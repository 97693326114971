<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>医患管理</el-breadcrumb-item>
            <el-breadcrumb-item>报名患者</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <el-date-picker v-model="date" type="daterange" size="small" align="right" unlink-panels range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd" clearable style="width: 260px;">
            </el-date-picker>
            <!-- 请输入患者信息： -->
            <el-input placeholder="请输入精确的姓名或电话号" size="small" clearable prefix-icon="el-icon-search" v-model="search"
                style="width: 220px;margin-left: 10px;" @clear="clearsearch">
            </el-input>
            <el-button type="primary" size="small" style="margin-left:10px" @click="query">查询</el-button>
            <el-button type="primary" size="small" style="margin-left:10px" @click="activeExportOut">导出</el-button>
        </div>
        <el-table :data="list" border style="width: 100%" height="720">
            <el-table-column prop="id" label="用户id" v-if="false" width="80"></el-table-column>
            <el-table-column prop="cust_name" label="姓名" fixed width="90"></el-table-column>
            <el-table-column prop="sex" label="性别" width="80"></el-table-column>
            <el-table-column prop="age" label="年龄" width="80"></el-table-column>
            <el-table-column prop="height" label="身高（cm）" width="110"></el-table-column>
            <el-table-column prop="weight" label="体重（kg）" width="110"></el-table-column>
            <el-table-column label="注册电话" prop="cust_tel" width="120"></el-table-column>
            <el-table-column label="报名电话" prop="tel" width="120"></el-table-column>
            <el-table-column label="所在地区" prop="area" width="140"></el-table-column>
            <el-table-column label="报名时间" prop="create_time" width="180"></el-table-column>
            <el-table-column label="希望寻找好医生的疾病" prop="ill" width="190" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="最想解决问题" prop="most_want" width="120" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column label="患病时间长度" prop="ill_stay_time" width="120" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column label="病情症状描述" prop="ill_desc" width="250" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="疾病对生活的影响" prop="ill_influ" width="150" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column label="是否治疗过" prop="is_cure" width="110" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_cure == 0">否</span>
                    <span v-else>是</span>
                </template>
            </el-table-column>
            <el-table-column label="目前治疗状态" prop="cure_status" width="180" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column label="其他确诊疾病情况" prop="other_ills" width="180" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column label="饮食和生活习惯" prop="habit" width="180" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column fixed="right" label="" width="80">
                <template slot-scope="scope">
                     <el-button @click="handleClick(scope.row)" type="primary"  size="mini">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <el-dialog title="详细信息" :visible.sync="dialogVisible" width="70%" style="text-align: left;">
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">姓名：</el-col>
                <el-col :span="16">{{lookmore.cust_name}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">性别：</el-col>
                <el-col :span="16">{{lookmore.sex}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">年龄：</el-col>
                <el-col :span="16">{{lookmore.age}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">身高（cm）：</el-col>
                <el-col :span="16">{{lookmore.height}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">体重（kg）：</el-col>
                <el-col :span="16">{{lookmore.weight}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">注册电话：</el-col>
                <el-col :span="16">{{lookmore.cust_tel}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">报名电话：</el-col>
                <el-col :span="16">{{lookmore.tel}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">所在地区：</el-col>
                <el-col :span="16">{{lookmore.area}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">报名时间：</el-col>
                <el-col :span="16">{{lookmore.create_time}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">希望寻找好医生的疾病：</el-col>
                <el-col :span="16">{{lookmore.ill}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">最想解决问题：</el-col>
                <el-col :span="16">{{lookmore.most_want}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">患病时间长度：</el-col>
                <el-col :span="16">{{lookmore.ill_stay_time}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">病情症状描述：</el-col>
                <el-col :span="16">{{lookmore.ill_desc}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">疾病对生活的影响：</el-col>
                <el-col :span="16">{{lookmore.ill_influ}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">是否治疗过：</el-col>
                <el-col :span="16">{{lookmore.is_cure == 0?'否':'是'}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">目前治疗状态：</el-col>
                <el-col :span="16">{{lookmore.cure_status}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">其他确诊疾病情况：</el-col>
                <el-col :span="16">{{lookmore.other_ills}}</el-col>
            </el-row>
            <el-row :gutter="20" class="heimar">
                <el-col :span="5" class="textr">饮食和生活习惯：</el-col>
                <el-col :span="16">{{lookmore.habit}}</el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-table :data="tableData" border style="width: 100%;display: none;" id="tableBox">
            <el-table-column prop="id" label="用户id" width="80"></el-table-column>
            <el-table-column prop="cust_name" label="姓名" width="90"></el-table-column>
            <el-table-column prop="sex" label="性别" width="90"></el-table-column>
            <el-table-column prop="age" label="年龄" width="95"></el-table-column>
            <el-table-column prop="height" label="身高（cm）" width="100"></el-table-column>
            <el-table-column prop="weight" label="体重（kg）" width="95"></el-table-column>
            <el-table-column label="注册电话" prop="cust_tel" width="120"></el-table-column>
            <el-table-column label="报名电话" prop="tel" width="120"></el-table-column>
            <el-table-column label="所在地区" prop="area" width="120"></el-table-column>
            <el-table-column label="报名时间" prop="create_time" width="180"></el-table-column>
            <el-table-column label="希望寻找好医生的疾病" prop="ill" width="180"></el-table-column>
            <el-table-column label="最想解决问题" prop="most_want" width="120"></el-table-column>
            <el-table-column label="患病时间长度" prop="ill_stay_time" width="120"></el-table-column>
            <el-table-column label="病情症状描述" prop="ill_desc" width="250"></el-table-column>
            <el-table-column label="疾病对生活的影响" prop="ill_influ" width="150"></el-table-column>
            <el-table-column label="是否治疗过" prop="is_cure" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_cure == 0">否</span>
                    <span v-else>是</span>
                </template>
            </el-table-column>
            <el-table-column label="目前治疗状态" prop="cure_status" width="180"></el-table-column>
            <el-table-column label="其他确诊疾病情况" prop="other_ills" width="180"></el-table-column>
            <el-table-column label="饮食和生活习惯" prop="habit" width="180"></el-table-column>
        </el-table>
    </div>
</template>
<script>
    const FileSaver = require("file-saver")
    const XLSX = require("xlsx")
    export default {
        inject: ['reload'],
        data() {
            return {
                dialogVisible: false,
                lookmore: {},
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                date: ['', ''],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                arr: [],
                tableData: [],
                search: ''
            }
        },
        mounted() {
            this.getcust(1);
        },
        methods: {
            clearsearch() {
                this.getcust(1);
            },
            getcust(page) {
                if (!this.date) {
                    this.date = ['', ''];
                }
                this.axios.get("/gu/get_gu_ills_all?page=" + page + "&size=" + this.PageSize + "&datetime1=" + this.date[0] + "&datetime2=" + this.date[1] )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.totalCount = res.data.count;
                            this.list = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getcust(currentPage);
            },
            query() {
                if (this.search != '') {
                    this.list = [];
                    this.axios.post('gu/search_gu_ills', this.qs.stringify({ keyword: this.search }))
                        .then(res => {
                            if (res.data.code == 0) {
                                this.totalCount = res.data.count;
                                this.list = res.data.result;
                            } else {
                                console.log(res)
                            }
                        })
                        .catch(res => {

                        })
                }else{
                    this.getcust(1);
                }
            },
            // 执行下载
            async activeExportOut() {
                // this.ableExportOut = true //设置可以下载
                //this.oldtableData = this.tableData //保存起就数据
                await this.setData()
                this.exportOut();
            },
            setData: async function () {
                this.arr = [];
                let num = Math.ceil(this.totalCount / this.PageSize);
                let m = '';
                let a = [];
                for (m = 1; m <= num; m++) {
                    a = await this.funA(m);
                    this.arr = this.arr.concat(a.data.result);
                }
                this.tableData = this.arr;
            },
            funA: async function (m) {
                return await this.axios.get("/gu/get_gu_ills_all?page=" + m + "&size=" + this.PageSize + "&datetime1=" + this.date[0] + "&datetime2=" + this.date[1])
            },
            //下载表格
            exportOut() {
                var xlsxParam = { raw: true };//转换成excel时，使用原始的格式
                var wb = XLSX.utils.table_to_book(document.querySelector("#tableBox"), xlsxParam)
                var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
                try {
                    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), new Date().getTime() + '.xlsx')
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                //  this.ableExportOut = false //设置不可下载
                //this.tableData = this.oldtableData //回复原来说的数据
                return wbout
            },
            handleClick(row) {
                this.dialogVisible = true;
                this.lookmore = row;
            }
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .zzks {
        float: left;
    }

    .tcform .el-dialog {
        width: 40%;
    }

    .textr {
        text-align: right;
    }

    .heimar {
        line-height: 20px;
        margin-bottom: 20px;
    }
</style>